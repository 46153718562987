<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="$router.back(-1)"
      :fixed="true"
      placeholder
    >
    </van-nav-bar>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="暂无更多数据"
      @load="onLoad"
    >
      <div
        class="list-item"
        v-for="(item, index) in list"
        :key="index"
        @click="enterShopDetail(item)"
      >
        <van-image width="110" height="110" :src="item.picUrls[0]" />
        <div class="item-right">
          <div class="title van-multi-ellipsis--l2">
            {{ item.name }}
          </div>
          <div class="price">
            <span>￥</span>
            <span class="price-num">{{ item.priceDown }}</span>
            <!-- <span v-if="item.priceDown !== item.priceUp">
              <span class="fz18">&nbsp;-&nbsp;</span>
              <span>￥</span>
              <span class="price-num">{{ item.priceUp }}</span>
            </span> -->
          </div>
          <div class="shop fz12 color999">{{ item.shopName }}</div>
          <div>
            <span v-if="item.reduceAmount">
              <span class="coupon fz12">
                <span>券￥</span>
                <span>{{ item.reduceAmount }}</span>
              </span>
              <span>&nbsp;</span>
            </span>
            <span class="fz12 color999">
              <span>已售</span>
              <span>{{ item.saleNum }}</span>
            </span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { goodsCategoryDetail } from "@/api/proprietary/proprietary";
export default {
  name: "classificationDetail",
  data() {
    return {
      title: "分类详情",
      obj: {
        current: 0,
        size: 10,
        categoryFirst: null,
        categorySecond: null,
      },

      total: 0,
      list: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.title = this.$route.query.text;
    if (this.$route.query.categoryId) {
      this.obj.categoryFirst = this.$route.query.categoryId;
    }
    if (this.$route.query.categoryId2) {
      this.obj.categorySecond = this.$route.query.categoryId2;
    }
    // this.getlist();
  },
  methods: {
    getlist() {
      goodsCategoryDetail(this.obj).then((res) => {
        console.log(res);
        // this.list = res.data.data.records;
        res.data.data.records.map((x) => {
          this.list.push(x);
        });
        this.total = res.data.data.total;
        this.loading = false;

        if (this.total === this.list.length) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.obj.current += 1;
      this.getlist();
    },

    enterShopDetail (item) {
      this.$router.push({
        path: '/itemDetail',
        query: { 
          id: item.id,
          first: 0,
          session: window.sessionStorage.getItem('third-session')
        },
      })
    }
  },
};
</script>

<style lang="less" scoped>
.list-item {
  display: flex;
  margin: 5px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  .item-right {
    margin-left: 10px;
    flex: 1;
    color: #333;
    .title {
      height: 40px;
    }
    .price {
      color: #a80000;
      .price-num {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .coupon {
      display: inline-block;
      width: 46px;
      height: 18px;
      background-image: url(/img/youhui-1.png);
      text-align: center;
      color: #fff;
    }
  }
}
</style>